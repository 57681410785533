import 'webp-in-css/polyfill';
import animate from './templates/diagrams/diagrams';
import mainMenu from './components/header/header';
import initSlider from './templates/main/main';
import clientsSlider from './templates/client-logos/client-logos';
import reviewsSlider from './templates/reviews/reviews';
import realizeIdeas from './templates/realize-ideas/realize-ideas';
import schedule from './templates/schedule/schedule';
import scheduleMobile from './templates/schedule/schedule-mobile';
import eventsSlider from './templates/events/events';
import casesSlider from './templates/cases/cases';
import initForm from './components/forms/form';
import { scrollToElement } from './scripts/scrollToElement';
import privacyPolicy from './components/privacy-policy/privacy-policy';
import ModalForm from './components/forms/modal-form';
import cookie from './components/cookie/cookie';
import insertYear from './components/footer/footer';
import { init as initSentry } from '../common/Sentry';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const disableToScrollAnchors = ['privacy-policy', 'error-modal'];

const anchors = document.querySelectorAll('a[href^="#"]');
for (let anchor of anchors) {
  anchor.addEventListener('click', (e) => {
    const elementId = anchor.getAttribute('href').substr(1);
    if (!disableToScrollAnchors.includes(elementId)) {
      e.preventDefault();
      scrollToElement(anchor.getAttribute('href').substr(1));
    }
  });
}

mainMenu();
initSlider();
clientsSlider();
reviewsSlider();
scheduleMobile();
realizeIdeas();
eventsSlider();
casesSlider();
initForm();
privacyPolicy();
ModalForm();
cookie();
insertYear();
initSentry('en');

animate.add(document.querySelectorAll('.js-animate'));
schedule(document.querySelector('.er_schedule'), window);
